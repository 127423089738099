/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const PatioRoofs = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Patio Roofs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Patio Roofs" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Close up of handheld drill"
            imageURL={data.ResidentialServices.childImageSharp.gatsbyImageData}
            titleText="Patio Roofs in Phoenix, Arizona"
          >
            <p>
              Patio roofs, whether stand-alone or for shed-style roofing over
              patios, are extremely common in Arizona. With a variety of outdoor
              covered areas, many of these roofs are in high exposure areas to
              sun, wind and various intense weather conditions. While
              maintenance such as roof coating can help extend the life of these
              roofs, repairs can address small issues before they grow into
              large-scale issues that require complete replacement.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Patio Roof Repairs and Maintenance
            </Heading>

            <p>
              Patio roofs can be built using several materials, all of which
              will require some level of maintenance. Any self-adhered or
              heat-applied materials will need to be coated and assessed for
              damage every 3-5 years or so (manufacturers have their own unique
              recommendations).
            </p>

            <p>
              Some roofs are made out of metal or plastic products. Metal
              requires much less maintenance, but can tend to have problems
              further down the road if not periodically inspected. This kind of
              damage can include screws and panels flying loose in high winds.
              Plastic-based materials have the potential to dry out and become
              cracked, which could cause a cave-in or break that allows weather
              through.
            </p>

            <p>
              With patio roofs, it is important to remember that the best time
              to inspect for damage or issues is after weather conditions change
              and/or severe weather has blown through. Checking periodically can
              ensure that there are no risks of further damage to the house or
              covering and keep your family safe. Call a professional to do the
              inspection and let Legacy help keep your patio roof in great
              condition.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "patio-decks-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ResidentialServices: file(
      relativePath: { eq: "close-up-off-handheld-drill.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default PatioRoofs;
